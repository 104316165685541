import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ActionType, ICreateBanner, IBanner, ISearchBanner, Pagination, SearchResult } from 'types';
import { formatDate, showMessage } from 'utils/helper';
import * as _ from 'lodash';
import ticketNewService from 'services/ticketNewService';

interface TicketNewState extends Pagination {
  ticketsNew: IBanner[];
  ticketNewDetail: IBanner;
  ticketNewAction: ActionType;
  showTicketNewModal: boolean;
  searchValues: ISearchBanner;
  totalTicketCode?: number;
  isLoading: boolean;
}

const initialState: TicketNewState = {
  page: 1,
  size: 10,
  total: 0,
  totalTicketCode: 0,
  ticketsNew: [],
  ticketNewDetail: {} as IBanner,
  ticketNewAction: ActionType.create,
  showTicketNewModal: false,
  searchValues: {},
  isLoading: false,
};

const ticketNewSlice = createSlice({
  name: 'TicketNew',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<TicketNewState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<TicketNewState['size']>) => {
      state.size = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<TicketNewState['searchValues']>) => {
      state.searchValues = action.payload;
    },
    setTicketNewAction: (state, action: PayloadAction<TicketNewState['ticketNewAction']>) => {
      state.ticketNewAction = action.payload;
      state.showTicketNewModal = true;
    },
    setShowTicketNewModal: (state, action: PayloadAction<TicketNewState['showTicketNewModal']>) => {
      state.showTicketNewModal = action.payload;
    },
    setTicketNewDetail: (state, action: PayloadAction<TicketNewState['ticketNewDetail']>) => {
      state.ticketNewDetail = action.payload;
      state.showTicketNewModal = true;
      state.ticketNewAction = ActionType.update;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchTicketNewThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchTicketNewThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchTicketNewThunk.fulfilled, (state, action: PayloadAction<SearchResult<IBanner[]>>) => {
        state.ticketsNew = action.payload.result.map((item) => ({
          ...item,
          create_at: formatDate(item.createAt),
          customerImageSrc: `${process.env.REACT_APP_API_URL}/public-file/getImage/${item.customerImage}`,
        }));
        state.total = action.payload.total;
        state.totalTicketCode = action.payload.totalTicketCode;
        state.isLoading = false;
      })
      .addCase(createTicketNewThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createTicketNewThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createTicketNewThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const createTicketNewThunk = createAsyncThunk(
  'createTicketNew',
  async (body: ICreateBanner, { dispatch, getState }) => {
    const { ticketNew } = getState() as RootState;
    const response = await ticketNewService.create(body);
    showMessage.success('Tạo mới vé thành công');
    dispatch(searchTicketNewThunk(ticketNew.searchValues));
    return response.data;
  }
);

export const searchTicketNewThunk = createAsyncThunk(
  'searchTicketNew',
  async (body: ISearchBanner, { dispatch, getState }) => {
    const { ticketNew } = getState() as RootState;
    const { data } = await ticketNewService.search(body, _.pick(ticketNew, ['page', 'size']));
    dispatch(setSearchValues(body));
    return data;
  }
);

export const updateBannerThunk = createAsyncThunk(
  'updateTicketNew',
  async (body: ICreateBanner, { getState, dispatch }) => {
    const { ticketNew } = getState() as RootState;
    const { data } = await ticketNewService.update(body, ticketNew.ticketNewDetail.id);
    dispatch(searchTicketNewThunk(ticketNew.searchValues));
    return data;
  }
);

export const deleteTicketNewThunk = createAsyncThunk('deleteTicketNew', async (id: number, { dispatch, getState }) => {
  const { ticketNew } = getState() as RootState;
  const { data } = await ticketNewService.delete(id);
  dispatch(searchTicketNewThunk(ticketNew.searchValues));
  showMessage.success('Xóa thành công!');
  return data;
});

export const downloadExcelThunk = createAsyncThunk('downloadExcel', async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}excel/download-htt`, {
      method: 'GET',
    });

    // Kiểm tra nếu response trả về thành công
    if (!response.ok) {
      throw new Error('Failed to download file');
    }

    // Chuyển đổi phản hồi thành Blob (dữ liệu file)
    const blob = await response.blob();

    // Tạo URL tạm thời cho Blob
    const url = window.URL.createObjectURL(blob);

    // Tạo thẻ <a> để kích hoạt tải file
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file_report.xlsx'; // Tên file sẽ được tải xuống
    a.click(); // Kích hoạt việc tải xuống file

    // Giải phóng URL sau khi tải xong
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
    showMessage.error('Có lỗi xảy ra rồi!');
  }
});

export const { setPage, setSize, setTicketNewDetail, setTicketNewAction, setShowTicketNewModal, setSearchValues } =
  ticketNewSlice.actions;
const { reducer: ticketNew } = ticketNewSlice;
export default ticketNew;
