import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ActionType, ICreateBanner, IBanner, ISearchBanner, Pagination, SearchResult } from 'types';
import { formatDate, showMessage } from 'utils/helper';
import * as _ from 'lodash';
import ticketLasaService from 'services/ticketLasaService';

interface TicketLasaState extends Pagination {
  ticketsLasa: IBanner[];
  ticketLasaDetail: IBanner;
  ticketLasaAction: ActionType;
  showTicketLasaModal: boolean;
  searchValues: ISearchBanner;
  totalTicketCode?: number;
  isLoading: boolean;
}

const initialState: TicketLasaState = {
  page: 1,
  size: 10,
  total: 0,
  totalTicketCode: 0,
  ticketsLasa: [],
  ticketLasaDetail: {} as IBanner,
  ticketLasaAction: ActionType.create,
  showTicketLasaModal: false,
  searchValues: {},
  isLoading: false,
};

const ticketLasaSlice = createSlice({
  name: 'TicketLasa',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<TicketLasaState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<TicketLasaState['size']>) => {
      state.size = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<TicketLasaState['searchValues']>) => {
      state.searchValues = action.payload;
    },
    setTicketLasaAction: (state, action: PayloadAction<TicketLasaState['ticketLasaAction']>) => {
      state.ticketLasaAction = action.payload;
      state.showTicketLasaModal = true;
    },
    setShowTicketLasaModal: (state, action: PayloadAction<TicketLasaState['showTicketLasaModal']>) => {
      state.showTicketLasaModal = action.payload;
    },
    setTicketLasaDetail: (state, action: PayloadAction<TicketLasaState['ticketLasaDetail']>) => {
      state.ticketLasaDetail = action.payload;
      state.showTicketLasaModal = true;
      state.ticketLasaAction = ActionType.update;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchTicketLasaThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchTicketLasaThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchTicketLasaThunk.fulfilled, (state, action: PayloadAction<SearchResult<IBanner[]>>) => {
        state.ticketsLasa = action.payload.result.map((item) => ({
          ...item,
          create_at: formatDate(item.createAt),
          customerImageSrc: `${process.env.REACT_APP_API_URL}/public-file/getImage/${item.customerImage}`,
        }));
        state.total = action.payload.total;
        state.totalTicketCode = action.payload.totalTicketCode;
        state.isLoading = false;
      })
      .addCase(createTicketLasaThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createTicketLasaThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createTicketLasaThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const createTicketLasaThunk = createAsyncThunk(
  'createTicketLasa',
  async (body: ICreateBanner, { dispatch, getState }) => {
    const { ticketLasa } = getState() as RootState;
    const response = await ticketLasaService.create(body);
    showMessage.success('Tạo mới vé thành công');
    dispatch(searchTicketLasaThunk(ticketLasa.searchValues));
    return response.data;
  }
);

export const searchTicketLasaThunk = createAsyncThunk(
  'searchTicketLasa',
  async (body: ISearchBanner, { dispatch, getState }) => {
    const { ticketLasa } = getState() as RootState;
    const { data } = await ticketLasaService.search(body, _.pick(ticketLasa, ['page', 'size']));
    dispatch(setSearchValues(body));
    return data;
  }
);

export const updateBannerThunk = createAsyncThunk(
  'updateTicketLasa',
  async (body: ICreateBanner, { getState, dispatch }) => {
    const { ticketLasa } = getState() as RootState;
    const { data } = await ticketLasaService.update(body, ticketLasa.ticketLasaDetail.id);
    dispatch(searchTicketLasaThunk(ticketLasa.searchValues));
    return data;
  }
);

export const deleteTicketLasaThunk = createAsyncThunk('deleteTicketLasa', async (id: number, { dispatch, getState }) => {
  const { ticketLasa } = getState() as RootState;
  const { data } = await ticketLasaService.delete(id);
  dispatch(searchTicketLasaThunk(ticketLasa.searchValues));
  showMessage.success('Xóa thành công!');
  return data;
});

export const downloadExcelThunk = createAsyncThunk('downloadExcel', async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}excel/download-htt`, {
      method: 'GET',
    });

    // Kiểm tra nếu response trả về thành công
    if (!response.ok) {
      throw new Error('Failed to download file');
    }

    // Chuyển đổi phản hồi thành Blob (dữ liệu file)
    const blob = await response.blob();

    // Tạo URL tạm thời cho Blob
    const url = window.URL.createObjectURL(blob);

    // Tạo thẻ <a> để kích hoạt tải file
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file_report.xlsx'; // Tên file sẽ được tải xuống
    a.click(); // Kích hoạt việc tải xuống file

    // Giải phóng URL sau khi tải xong
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
    showMessage.error('Có lỗi xảy ra rồi!');
  }
});

export const { setPage, setSize, setTicketLasaDetail, setTicketLasaAction, setShowTicketLasaModal, setSearchValues } =
  ticketLasaSlice.actions;
const { reducer: ticketLasa } = ticketLasaSlice;
export default ticketLasa;
