export const path = {
  root: '/', // home
  notFound: '*', // not found
  login: '/login', // đăng nhập
  admin: 'admin', // admin
  group_permission: 'group_permission', // nhóm quyền
  permission: 'permission', // quyền
  customer: 'customer', // quản lý khách hàng
  customerDetail: '/customer/:id', // quản lý khách hàng
  event: 'event', // event
  eventDetail: '/event/:id', // event
  withdrawal: 'withdrawal', // quản lý trả thưởng
  dashboard: 'dashboard', // admin
  banner: 'banner', // quản lý banner
  ticket_ht: 'ticket_ht',
  ticket_ml: 'ticket_ml',
  ticket_new: 'ticket_new',
  ticket_lasa: 'ticket_lasa',
  league: 'league', // quản lý giải đấu
  team: 'team',
  dial: 'dial',
  dial_new: 'dial_new',
  dial_ml: 'dial_ml',
  dial_lasa: 'dial_lasa',
};
