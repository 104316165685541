import baseService from './baseService';

const API_END_POINT = 'dialML/';

const dialMLService = {
  search: () => {
    return baseService.get<any>(`${API_END_POINT}`);
  },
  dial: () => baseService.post(`${API_END_POINT}`),
};

export default dialMLService;
