import { IBanner, ICreateBanner, ISearchBanner, Pagination, SearchResult } from 'types';
import baseService from './baseService';

const API_END_POINT = '/ticketsML/';

const ticketMLService = {
  search: (body: ISearchBanner, params: Pagination) => {
    console.log(1)
    return baseService.post<SearchResult<IBanner[]>>(`${API_END_POINT}searchAll`, body, params);
  },
  create: (body: ICreateBanner) =>
    baseService.post(`${API_END_POINT}create`, { ...body }),
  update: (body: ICreateBanner, id: IBanner['id']) => baseService.put(`${API_END_POINT}update/${id}`, body),
  delete: (id: IBanner['id']) => baseService.delete(`${API_END_POINT}delete/${id}`),
  getDetail: (id: IBanner['id']) => baseService.get(`${API_END_POINT}getById/${id}`),
};

export default ticketMLService;
